import { SVGAttributes } from 'react';
import Image from './image';
import { Link } from '@inertiajs/react';

export default function AppLogoIcon(props: SVGAttributes<SVGElement> & { useLink?: boolean }) {
    const logo = <Image src="/logo.png" alt="Learnable" {...props} />
    
    return (
        props.useLink ? <Link href="/">
            {logo}
        </Link> : logo
          );
}
