import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ImageProps {
    src: string;
    alt: string;
    className?: string;
    width?: number;
    height?: number;
    priority?: boolean;
    sizes?: string;
    fill?: boolean;
}

export default function Image({
                                  src,
                                  alt,
                                  className = '',
                                  width,
                                  height,
                                  fill = false,
                                ...props
                              }: ImageProps) {

    return <LazyLoadImage
        alt={alt}
        height={height}
        fill={fill}
        src={src} // use normal <img> attributes as props
        width={width}
        className={className}
        {...props}
    />
}
