import { Head, usePage } from '@inertiajs/react';
import { PageProps } from '@inertiajs/core'; // Adjust the import path if necessary
import { Toaster } from 'sonner';

export default function PageLayout({ children, ...props } : { children: React.ReactNode }) {
    const { meta } = usePage<PageProps>().props;
    const { title, description } = props as { title?: string; description?: string };
    const { canonical } = meta || {};

    return (
        <>
            <Head title="Welcome">
                <link rel="preconnect" href="https://fonts.bunny.net" />
                <link href="https://fonts.bunny.net/css?family=instrument-sans:400,500,600" rel="stylesheet" />

                <title>{title || meta?.title}</title>
                <meta name="description" content={description || meta?.description} />
                <meta property="og:title" content={title || meta?.title} />
                <meta property="og:description" content={description || meta?.description} />
                <meta property="og:image" content={meta?.ogImage} />
                <meta property="og:url" content={meta?.canonical} />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href={meta?.canonical} />
            </Head>
            {children}
            <Toaster />
        </>
    )
}
